<template>
    <v-container fill-height class="d-flex">
        <v-card-text>
            <div align="center" class="question text-h5">
                <v-icon size="4rem" class="icon" color="teal darken-2">mdi-gamepad-variant</v-icon>
                <p class="text-h5">{{ currIndex + 1 }}. {{ question.question }}</p>
            </div>
            <v-row full-width justify="center" align="center">
                <v-card elevation="0" class="mx-auto" tile>
                    <v-list-item v-for="(answer, i) in question.responses.filter(answer => answer)" :key="i">
                        <AnswerItem
                            :checkedAnswer="i === checkedAnswer"
                            :question="question"
                            class="answer"
                            :answers="answer"
                            :id="i + 1"
                        />
                    </v-list-item>
                </v-card>
            </v-row>
            <v-card-text class="text-right">
                <v-btn
                    v-if="checkedAnswer != null"
                    @click="onNext"
                    text
                    class="text-subtitle-1 text-decoration-underline"
                    >> {{ $t("nextquestion") }}</v-btn
                >
            </v-card-text>
        </v-card-text>
    </v-container>
</template>

<script>
import AnswerItem from "./AnswerItemComponent"
import { mapActions, mapGetters } from "vuex"
export default {
    name: "Question",
    props: ["question", "currIndex"],
    computed: {
        ...mapGetters("quizz", ["checkedAnswer"])
    },
    components: {
        AnswerItem
    },
    methods: {
        ...mapActions("quizz", ["gotoNext"]),
        onNext(event) {
            this.$emit('addResponse', {
                response: {
                    questionObj: this.question,
                    responseNumber: this.checkedAnswer + 1,
                    goodResponse: this.question.response == this.checkedAnswer 
                }
            });
            this.gotoNext({ index: this.currIndex + 1 });
        }
    }
}
</script>

<style scoped>
.answer {
    margin-top: 0rem;
}
.icon {
    margin-bottom: 3rem;
}
.question {
    margin-bottom: 1rem;
}
.v-btn {
    text-transform: none;
}
</style>
