/* eslint-disable no-console */
import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export const quizzStore = {
    state: {
        userAnswers: [],
        quizz: null,
        listQuestions: [],
        currentCheckedAnswer: null,
        currentQuestion: null,
        responseOfQuestion: null,
        goodResponseCount: 0,
        finished: false
    },
    getters: {
        checkedAnswer(state) {
            return state.currentCheckedAnswer
        }
    },
    mutations: {
        SAVE_ANSWER(state, answer) {
            let answerState = state.userAnswers
            let idQuestionWillAnswered = answer.question.id

            state.currentCheckedAnswer = answer.responseNumber - 1

            answerState.forEach(ans => {
                if (ans.question.question.id === idQuestionWillAnswered) {
                    if (answer.checked) {
                        ans.responseNumber = answer.responseNumber
                        ans.goodResponse = answer.goodResponse
                        if (answer.goodResponse) state.goodResponseCount += 1
                    } else {
                        ans.responseNumber = null
                        state.currentCheckedAnswer = null
                        ans.goodResponse = false
                    }
                }
                return ans
            })
        },
        SET_CURRENT_QUESTION(state, question) {
            let answerObj = { question: question, responseNumber: null, goodResponse: false }
            state.currentQuestion = question
            state.userAnswers.push(answerObj)
            state.currentCheckedAnswer = null
            state.responseOfQuestion = question.question.response
        },
        SET_LISTQUESTION(state, listQuestion) {
            state.listQuestions = [...listQuestion]
        },
        INIT_USER_ANSWER(state, answersList) {
            state.userAnswers = [...answersList]
        },
        INIT_GOOD_RESPONSE_COUNT(state, goodResponseCount) {
            state.goodResponseCount = goodResponseCount
        },
        FINISH(state, is_finished) {
            state.finished = is_finished
        }
    },
    actions: {
        finishQuizz({ commit }, is_finished) {
            commit("FINISH", is_finished)
        },
        setCurrentQuestion({ commit }, question) {
            commit("SET_CURRENT_QUESTION", question)
        },
        initUserAnswer({ commit }, answerList) {
            commit("INIT_USER_ANSWER", answerList)
        },
        initGoodResponseCount({ commit }, goodResponseCount) {
            commit("INIT_GOOD_RESPONSE_COUNT", goodResponseCount)
        },
        saveAnswer({ commit }, answer) {
            commit("SAVE_ANSWER", answer)
        },
        setListQuestion({ commit }, listQuestions) {
            commit("SET_LISTQUESTION", listQuestions)
        },
        gotoNext({ commit, state }, data) {
            const { index } = data
            let nextQuestion = state.listQuestions[index]

            if (nextQuestion) {
                commit("SET_CURRENT_QUESTION", {
                    question: nextQuestion,
                    index: index
                })
            } else {
                commit("FINISH", true)
            }
        }
    }
}
