<template>
    <v-row class="container-of-answer">
        <v-col cols="1">
            <v-icon large :color="checkedAnswer ? 'teal' : 'white'" class="check">mdi-check</v-icon>
        </v-col>
        <v-col cols="1">
            <v-btn
                v-model="checked"
                @click="
                    () => {
                        saveAnswer({ question: question, responseNumber: id, checked: !checkedAnswer ,goodResponse:id===responseOfQuestion})
                    }
                "
                :class="checkedAnswer ? 'btn active white--text' : 'btn'"
                outlined
                color="black"
            >{{ String.fromCharCode(id + 64) }}</v-btn>
        </v-col>
        <v-col cols="10">
            <span class="ml-9 text-subtitle-1">{{ answers }}</span>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex"
export default {
    name: "AnswerItem",
    data() {
        return { checked: false }
    },
    props: ["answers", "id", "question", "checkedAnswer"],
    computed: {
        ...mapState("quizz", ["responseOfQuestion"])
    },
    methods: {
        ...mapActions("quizz", ["saveAnswer"])
    }
}
</script>

<style scoped>
.v-btn {
    min-width: 0 !important;
}
.active {
    background-color: black;
    color: white;
}
.container-of-answer {
    overflow: hidden;
    min-width: 300px;
    align-items: center;
}
</style>
