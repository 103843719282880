import { Question } from "../models/Question"
import { Quizz } from "../models/Quizz"

const java_question = new Question(
    1,
    1,
    "How to print hello world in java ?",
    [
        "System.print('hello world')",
        "print('hello world')",
        "console.log('hello world')",
        "System.out.print('hello world')"
    ],
    4
)

const javascript_question = new Question(
    2,
    2,
    "How to create a constant variable with js ?",
    ["With const", "With var", "With let"],
    1
)

const kotlin_question = new Question(
    3,
    3,
    "kotlin language is a powerul language for :",
    [
        "Web development",
        "CrossPlatform mobile development",
        "Android app development",
        "Desktop app development"
    ],
    3
)

const ballerina_question = new Question(
    4,
    4,
    "Ballerina is a programming language for :",
    [
        "Microservices and cloud service",
        "Testing Microservices",
        "Learning ballerina dance",
        "Continuous integration"
    ],
    1
)

export const quizz_with_empty_response = new Quizz(
    [java_question, javascript_question, kotlin_question, ballerina_question],
    {
        responses: [],
        user: "jane@gmail.com",
        responsesCount: 0,
        goodResponsesCount: 0
    }
)

export const quizz_with_prefilled_response = new Quizz(
    [java_question, javascript_question, kotlin_question, ballerina_question],
    {
        user: "jane@gmail.com",
        responsesCount: 2,
        goodResponsesCount: 1,
        responses: [
            { question: java_question, responseNumber: 2, goodResponse: false },
            { question: javascript_question, responseNumber: 1, goodResponse: true }
        ]
    }
)

export const quizz_with_all_response = new Quizz(
    [java_question, javascript_question, kotlin_question, ballerina_question],
    {
        responses: [
            { question: java_question, responseNumber: 4, goodResponse: true },
            { question: javascript_question, responseNumber: 1, goodResponse: true },
            { question: kotlin_question, responseNumber: 3, goodResponse: true },
            { question: ballerina_question, responseNumber: 1, goodResponse: true }
        ],
        user: "jane@gmail.com",
        responsesCount: 4,
        goodResponsesCount: 4
    }
)

export default {
    emptyResponse: quizz_with_empty_response,
    withSomeResponse: quizz_with_prefilled_response,
    allAnswered: quizz_with_all_response
}
