import Vue from "vue"
import Vuetify from "vuetify/lib"
import "vuetify/dist/vuetify.min.css"
import {
    VApp,
    VMain,
    VCol,
    VRow,
    VContainer,
    VCardText,
    VIcon,
    VBtn,
    VListItem,
    VCard
} from "vuetify/lib"
Vue.use(Vuetify, {
    components: { VApp, VMain, VCol, VRow, VContainer, VCardText, VIcon, VBtn, VListItem, VCard }
})

export default new Vuetify({})
