<template>
    <v-container fluid align="center" justify="center">
        <v-col cols="12" align="center">
            <v-card-text class="text-h5">{{ $t("allanswerd") }}</v-card-text>
        </v-col>
    </v-container>
</template>

<script>
export default {
    name: "Score"
}
</script>
