import Vue from "vue"
import Vuex from "vuex"
import { quizzStore } from "./quizzStore"

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        quizz: {
            namespaced: true,
            ...quizzStore
        }
    }
})
