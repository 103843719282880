<template>
    <v-container fluid fill-height full-width>
        <v-row justify="center" align="center">
            <v-col cols="12" xs="12">
                <v-container fluid>
                    <Score v-if="finished" />
                    <Question
                        v-if="!finished"
                        :question="currentQuestion.question"
                        :currIndex="currentQuestion.index"
                        @addResponse="bubbleAddResponse"
                    />
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Question from "../components/QuestionComponent"
import Score from "../components/ScoreComponent"
import { mapActions, mapState } from "vuex"
export default {
    name: "Quizz",
    props: ["questions", "responses"],
    components: {
        Question,
        Score
    },
    computed: {
        ...mapState("quizz", ["currentQuestion", "finished"])
    },
    methods: {
        ...mapActions("quizz", [
            "setCurrentQuestion",
            "initUserAnswer",
            "initGoodResponseCount",
            "finishQuizz",
            "setListQuestion"
        ]),
        bubbleAddResponse(e) {
            this.$emit('addResponse', e);
        }
    },
    created() {
        let listQuestions = this.questions
        let listAnswerdQuestion = new Set(this.responses.responses.map((u_answer) => u_answer.questionObj.id))
        listQuestions = listQuestions.filter((quest) => {
            return !listAnswerdQuestion.has(quest.id.toString())
        })

        this.setListQuestion(listQuestions)
        this.initUserAnswer(this.responses.responses)
        this.initGoodResponseCount(this.responses.goodResponsesCount)
        if (listQuestions.length > 0)
            this.setCurrentQuestion({
                question: listQuestions[0],
                index: 0
            })
        else this.finishQuizz(true)
    }
}
</script>

<style scoped>
.v-container {
    padding: 0;
}
</style>
