<template>
    <v-app quizz-vuetify class="quizz-vuetify">
        <v-main>
            <Quizz :questions="questions" :responses="responses" @addResponse="bubbleAddResponse" />
        </v-main>
    </v-app>
</template>

<style src="@mdi/font/css/materialdesignicons.css"></style>

<script>
import { store } from "../src/store/store"
import vuetify from "../src/plugins/vuetify"
import Quizz from "./components/QuizzComponent"
import i18n from "../src/i18n"
import { quizz_with_empty_response } from "./data/QuizzData"

export default {
    store,
    vuetify,
    i18n,
    name: "App",
    props: {
        questions: {
            type: Array,
            default: () => quizz_with_empty_response.listQuestions
        },
        responses: {
            type: Object,
            default: () => quizz_with_empty_response.responses
        },
        lang: {
            type: String,
            default: "en"
        }
    },
    methods: {
        bubbleAddResponse(e) {
            this.$emit("addresponse", e)
        }
    },
    components: {
        Quizz
    },
    created() {
        i18n.locale = this.lang
    }
}
</script>

<style>
[quizz-vuetify] .v-application--wrap {
    min-height: 0;
}

[quizz-vuetify] {
    overflow: auto !important;
}
</style>
